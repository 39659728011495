<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="login-box-bank">
      <el-button
        type="text"
        @click="$router.go(-1)"
        class="el-icon-back fs16"
      ></el-button>
      <h3 class="title">{{$t(1089)}}</h3>
      <h3>{{$t(1090)}}：</h3>
      <div v-loading="loading" class="content-nobg">
      <div class="recive mt10">
        <div v-if="cardsData.length > 0">
          <el-table :data="cardsData" class="c2c-table" :key="cardsData">
            <el-table-column prop="realname" :label="$t(1052)"> </el-table-column>
            <el-table-column prop="cardNo" :label="$t(1051)" width="200">
              <template slot-scope="scope">
                {{ scope.row.cardNo.replace(/.*(.{3})/, '****$1') }}
              </template>
            </el-table-column>
            <el-table-column prop="bankBranch" :label="$t(1050)">
            </el-table-column>
            <el-table-column prop="currency" :label="$t(1091)"> </el-table-column>
            <el-table-column :label="$t('operate')" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="color-primary"
                  @click="deleteBank(scope.row.id)"
                  >{{$t(314)}}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span v-else>{{ $t(1053) }}</span>
      </div>
      </div>
      <h3 class="mt30">{{ $t(1089) }}：</h3>
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item :label="`${$t(1052)}：`" prop="realname">
          <el-input v-model="form.realname"> </el-input>
        </el-form-item>
        <el-form-item :label="`${$t(1051)}：`" prop="cardNo">
          <el-input v-model="form.cardNo"> </el-input>
        </el-form-item>
        <el-form-item :label="`${$t(1050)}：`" prop="bankBranch">
          <el-input v-model="form.bankBranch"> </el-input>
        </el-form-item>
        <el-form-item :label="`${$t(1099)}(${$t(208)})：`" prop="bankBranchName">
          <el-input v-model="form.bankBranchName"></el-input>
        </el-form-item>
        <el-form-item :label="`${$t(1091)}：`" prop="currency">
           <el-select v-model="form.currency" :placeholder="$t(1097)">
                <el-option
                  v-for="(item,i) in currencyData"
                  :key="i"
                  :label="item.currency"
                  :value="item.currency"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="`${$t(1092)}(${$t(208)})：`" prop="bankCode">
          <el-input v-model="form.bankCode"></el-input>
        </el-form-item>
        <el-form-item v-if="form.currency==='JPY'" :label="`${$t(1120)}(${$t(208)})：`" prop="financialName">
          <el-input v-model="form.financialName"></el-input>
        </el-form-item>
        <el-form-item v-if="form.currency==='JPY'" :label="`${$t(1121)}(${$t(208)})：`" prop="financialBranch">
          <el-input v-model="form.financialBranch"></el-input>
        </el-form-item>
        <el-form-item v-if="form.currency==='JPY'" :label="`${$t(1122)}(${$t(208)})：`" prop="financialBranchCode">
          <el-input v-model="form.financialBranchCode"></el-input>
        </el-form-item>
        <el-form-item v-if="form.currency==='JPY'" :label="`${$t(1123)}(${$t(208)})：`" prop="financialCode">
          <el-input v-model="form.financialCode"></el-input>
        </el-form-item>
        <el-form-item v-if="form.currency==='JPY'" :label="`${$t(1124)}(${$t(208)})：`" prop="receiver">
          <el-input v-model="form.receiver"></el-input>
        </el-form-item>
        <el-form-item v-if="form.currency==='JPY'" :label="`${$t(1125)}(${$t(208)})：`" prop="goldCity">
          <el-input v-model="form.goldCity"></el-input>
        </el-form-item>
        <el-form-item v-else :label="`${$t(204)}(${$t(208)})：`" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <div class="pt20 pb10">
          <el-button type="primary" @click="doSubmit" :loading="loading">{{
            $t(127)
          }}</el-button>
        </div>
        <!-- <el-button type="text" @click="$router.push('/register')">{{ $t(104) }}</el-button> -->
      </el-form>
      <div class="corpyrights">
        © 2017 - 2025 {{ configObj.appName }} All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
import loginMixins from "@/mixins/login.js";
import SimpleHeader from "@/components/SimpleHeader";
import { getBankCards,deleteBankCard,getCurrencies,addBankCard } from "@/api";
import utils from "@/utils/misc";
export default {
  name: "UpdateMobileEmail",
  components: { SimpleHeader },
  mixins: [loginMixins],
  data() {
    return {
      form: {
        realname: "", // 姓名
        cardNo: "", // 銀行帳號
        bankBranch: "", // 銀行名稱
        bankBranchName: "", // C2C.38
        currency: "", // 法幣
        bankCode: "", // 銀行編碼
        financialName: '',
        financialBranch: '',
        financialBranchCode: '',
        financialCode: '',
        receiver: '',
        goldCity: '',
        remark: ''
      },
      cardsData: [],
      currencyData: []
    };
  },
  computed: {
    configObj() {
      return window.configObj;
    },
    rules() {
       return {
        realname:[{
          validator: (rule,value,callback) => {
            if (!value) {
              return callback(new Error(this.$t(1098)))
            }
            callback()
          }
        }],
        cardNo:[{
          validator: (rule,value,callback) => {
            if (!value) {
              return callback(new Error(this.$t(1094)))
            }
            callback()
          }
        }],
        bankBranch:[{
          validator: (rule,value,callback) => {
            if (!value) {
              return callback(new Error(this.$t(1095)))
            }
            callback()
          }
        }],
        currency:[{
          validator: (rule,value,callback) => {
            if (!value) {
              return callback(new Error(this.$t(1096)))
            }
            callback()
          }
        }]
      }
    },
  },
  mounted() {
    this.fetchBankCards();
  },
  methods: {
    fetchBankCards() {
      this.loading = true;
      Promise.all([getBankCards(), getCurrencies()])
        .then((res) => {
          this.cardsData = res[0].cards ? res[0].cards : [];
          this.currencyData = res[1].list ? res[1].list : [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteBank(id) {
      this.confirmWarning({
        title : this.$t(1093),
        customClass: "financial-confirmWarning",
      }).then(() => {
      deleteBankCard(id).then((res) => {
          this.$message.success(this.$t(1062))
          this.fetchBankCards();
        })
      })
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const obj = {...this.form, remark: ''}
          const jpyFields = [
            'financialName',
            'financialBranch',
            'financialBranchCode',
            'financialCode',
            'receiver',
            'goldCity'
          ]
          for (const key of jpyFields) {
            if (this.form.currency === 'JPY') {
              obj.remark += obj.remark ? `-${this.form[key]}` : this.form[key]
            } else {
              delete obj[key]
            }
          }
          obj.remark = obj.remark || this.form.remark || ''
          addBankCard(obj).then((res) => {
          this.$message.success(this.$t(1062))
          this.fetchBankCards();
        })
        .finally(() => {
          this.loading = false;
        });
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.login-box-bank {
  color: #1e2329;
  width: 800px;
  margin: 2vh auto 10vh;
  .title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  .tab {
    margin: 40px 0px 20px 0px;
    color: #707a8a;
    font-size: 16px;
    .item {
      display: inline-block;
      cursor: pointer;
      padding: 8px 24px;
      margin-right: 12px;
      &.active {
        background-color: #f5f5f5;
        color: #1e2329;
        border-radius: 8px;
      }
    }
  }
  .el-input__inner {
    height: 46px;
    line-height: 46px;
  }
}
.corpyrights {
  position: absolute;
  bottom: 10px;
  text-align: center;
  font-size: 12px;
  width: 100%;
  left: 0;
}
.financial-confirmWarning {
  .el-message-box__title {
    text-align: center;
  }
  .el-message-box__btns {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    button {
      width: 200px;
      margin: 0 5px 0;
    }
  }
}
</style>
